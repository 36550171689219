<!-- 添加数字货币 -->
<template>
  <div id="currency">
    <van-nav-bar :title="$t('checkType.tab2')" left-arrow @click-left="onClickLeft"></van-nav-bar>
    <!-- 选择货币 -->
    <van-cell class="top-cell" @click="choiceHb" is-link>
      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <div class="cell-title">
          <div class="icon_warp">
            <img v-if="checkedBi && checkedBi.code == 'BTC'" src="../../../assets/b_img/BTC.png" alt="" />
            <img v-else-if="checkedBi && checkedBi.code == 'USDT'" src="../../../assets/b_img/USDT.png" alt="" />
            <img v-else-if="checkedBi && checkedBi.code == 'ETH'" src="../../../assets/b_img/ETH.png" alt="" />
            <img v-else-if="checkedBi && checkedBi.code == 'LTC'" src="../../../assets/b_img/LTC.png" alt="" />
            <img v-else-if="checkedBi && checkedBi.code == 'USDC'" src="../../../assets/b_img/USDC.png" alt="" />
            <i v-else class="iconfont icon-yingbi"></i>
          </div>

          <span class="custom-title" v-if="!checkedBi">{{ $t('m_currencyform.qxzhb') }}</span>
          <div class="bi_warp" v-else>
            <span class="custom-title">{{ checkedBi.name }}</span>
            <span class="custom-title-msg">{{ checkedBi.code }}</span>
          </div>
        </div>
      </template>
    </van-cell>
    <van-cell-group>
      <van-field v-model="form.name" :label="$t('biform.realname')" :placeholder="$t('m_currencyform.realname')" />
    </van-cell-group>
    <van-cell-group v-if="!checkedBi">
      <van-field v-model="value" :label="$t('biform.llx')" :placeholder="$t('m_currencyform.qxzhb')" />
    </van-cell-group>
    <van-cell-group v-else>
      <div class="van-cell">
        <div class="van-cell__title van-field__label">
          <span>{{ $t('biform.llx') }}</span>
        </div>
        <div class="van-cell__value van-field__value">
          <div class="type">
            <ul>
              <li @click="checedType(item, index)" v-for="(item, index) in checkedBi.type_list" :key="index"
                :class="{ active: typeIndex == index }">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-cell-group>
    <van-cell-group>
      <van-field v-model="form.address" :label="$t('biform.skdz')" :placeholder="$t('m_currencyform.address')" />
    </van-cell-group>
    <div style="margin: 16px">
      <van-button class="addbtn" type="info" @click="bilianAddFun">{{ $t('m_bankform.addbtn') }}</van-button>
    </div>
    <van-popup class="popup-huobi" v-model="show" position="bottom" closeable close-icon-position="top-right"
      :style="{ height: '100%' }">
      <!-- 头部 -->
      <div class="scroll-header">
        <div class="title">{{ $t('m_currencyform.xz') }}</div>
      </div>
      <van-list>
        <van-cell @click="changeHb(item)" v-for="(item, index) in list" :key="index">
          <template #title>
            <div class="cell-title">
              <div class="icon_warp">
                <img v-if="item.code == 'BTC'" src="../../../assets/b_img/BTC.png" alt="" />
                <img v-if="item.code == 'USDT'" src="../../../assets/b_img/USDT.png" alt="" />
                <img v-if="item.code == 'ETH'" src="../../../assets/b_img/ETH.png" alt="" />
                <img v-if="item.code == 'LTC'" src="../../../assets/b_img/LTC.png" alt="" />
                <img v-if="item.code == 'USDC'" src="../../../assets/b_img/USDC.png" alt="" />
              </div>
              <div class="huibi_info">
                <span class="custom-title">{{ item.name }}</span>
                <span class="custom-title-msg">{{ item.code }}</span>
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
    </van-popup>
  </div>
</template>

<script>
import { bilianAdd } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      show: false,
      value: "",
      list: [
        {
          id: 6,
          type: 0,
          name: this.$t('m_currencyform.btb'),
          code: "BTC",
          type_list: ["BTC", "HBTC"],
          flag: 1,
          limit: 0,
        },
        {
          id: 7,
          type: 1,
          name: this.$t('m_currencyform.tdb'),
          code: "USDT",
          type_list: ["TRC20", "OMNI"],
          flag: 1,
          limit: 0,
        },
        {
          id: 8,
          type: 2,
          name: this.$t('m_currencyform.ytf'),
          code: "ETH",
          type_list: ["ETH"],
          flag: 1,
          limit: 1,
        },
        {
          id: 9,
          type: 3,
          name: this.$t('m_currencyform.ltb'),
          code: "LTC",
          type_list: ["LTC"],
          flag: 1,
          limit: 1,
        },
        {
          id: 10,
          type: 4,
          name: this.$t('m_currencyform.usd'),
          code: "USDC",
          type_list: ["USDC"],
          flag: 1,
          limit: 0,
        },
      ],
      checkedBi: null,
      typeIndex: 0,
      form: {
        type: "",
        name: "",
        cate: "",
        address: "",
      },
      timer: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() { },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    /* 打开货币选择 */
    choiceHb() {
      this.show = true;
    },
    /* 选择货币 */
    changeHb(item) {
      this.show = false;
      console.log("选择了货币");
      this.typeIndex = 0;
      this.checkedBi = item;
    },
    /* 选择币类型 */
    checedType(item, index) {
      this.typeIndex = index;
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    /* 添加方法 */
    bilianAddFun() {
      if (!this.checkedBi) {
        Toast.fail(this.$t('common.addmsg'));
        return;
      }
      this.form.type = this.checkedBi.type;
      this.form.cate = this.checkedBi.type_list[this.typeIndex];
      this.loadingCom();
      bilianAdd(this.form).then((res) => {
        Toast.clear();
        if (res.code + "" === "200") {
          Toast.success(this.$t('common.addSussess'));
          this.timer = setTimeout(() => {
            this.onClickLeft();
          }, 1000);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../assets/css/mobile.less";

.van-nav-bar {
  /* background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important; */
  background-color: rgb(50, 59, 92) !important;
}

/deep/.van-nav-bar__title {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

/deep/.van-nav-bar .van-icon {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

/deep/.van-field__label {
  color: #ffffff;
}

.van-cell-group {
  background-color: #222;
}

.van-cell {
  padding: 0.28rem 0.3rem;
  font-size: 0.3rem;
}

#currency {
  width: 100%;
  height: 100%;
  background-color: #303e70;
}

.top-cell {
  display: flex;
  align-items: center;
  background-color: #222;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.cell-title {
  display: flex;
  align-items: center;

  .icon_warp {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    background-color: #d6a23e;

    img {
      width: 100%;
      height: 100%;
    }

    .iconfont {
      color: #fff;
      font-size: 0.4rem;
    }
  }

  .custom-title {
    font-size: 0.28rem;
    margin-left: 0.1rem;
  }
}

.popup-huobi {
  background-color: #303e70;
}

.scroll-header {
  height: 40px;
  line-height: 40px;
  /* background-image: linear-gradient(0deg, #ceab77 20%, #eddbc1 100%); */
  background-color: rgb(50, 59, 92);
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 0.3375rem;
}

.huibi_info {
  display: flex;
  flex-direction: column;
}

.custom-title-msg {
  color: #999999;
  font-size: 0.24rem;
  margin-left: 0.1rem;
}

.type {
  ul {
    display: flex;
    align-items: center;

    li.active {
      background-color: #2979ff;
      color: #fff;
    }

    li {
      padding: 0 0.15rem;
      height: 0.55rem;
      border-radius: 0.1rem;
      border: solid 1px #2979ff;
      color: #2979ff;
      font-weight: 500;
      margin-right: 0.15rem;
      font-size: 0.32rem;
      line-height: 0.55rem;
    }
  }
}

.bi_warp {
  display: flex;
  flex-direction: column;
}

/deep/.van-popup__close-icon--top-right {
  top: 0.15rem;
  right: 16px;
}

/deep/.van-popup__close-icon {
  position: absolute;
  z-index: 1;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.addbtn {
  width: 100%;
  /* background-image: linear-gradient(0deg, #ceab77, #eddbc1); */
  color: #000;
  border: none;
  font-size: 18px;

  background-color: #2979ff;
  border-color: #2979ff;

  .van-button__text {
    color: #ffffff;
  }
}
</style>